import React, { useContext, useRef } from "react";
import styled from "styled-components";
import BackArrow from '../../../../assets/img/back-arrow.inline.svg'
import License from "../general/licenses";
import { StateContext } from "../../../../contextApi";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../general/Button";

const RECAPTCHA_SITEKEY = `6Lf2qHopAAAAALTCIoyeAKeCrjBp0D-zBXjlPfbZ`;
function BVNVerification({
    handleBvnInputChange,
    handleSubmit,
    bvnInputs,
    ...props
}) {

    const reCaptchaRef = useRef()
    const [{ }, dispatch] = useContext(StateContext)

    const openBVNDialForm = () => {
        dispatch({
            key: 'dialBVNForm',
            value: true,
            type: "CHANGE_VIEW_MODALS",
        })
    }

    const executeCaptcha = async () => {
        dispatch({
            key: 'verifyBVNForm',
            value: true,
            type: "CHANGE_VIEW_MODALS",
        })

        const token = await reCaptchaRef.current.executeAsync();
        handleSubmit(token)
    };

    return (
        <FormLayoutWrapper>

            <div>
                {props.activepage > 0 && (
                    <button
                        onClick={props.handleGoBack}
                        type="button"
                        className="back-to color-primary btn--web"
                    >
                        <span className="backArrow">
                            <BackArrow />
                        </span>
                        Back
                    </button>
                )}

                <h4 className="kuda-page--title color-primary text-center text-xlbold mb-4">
                    What’s Your BVN?
                </h4>
                <PageHeading>
                    <p>We need your Bank Verification Number (BVN) to confirm who you are.</p>
                </PageHeading>

                <div className="bvn-card--wrap">

                    {props.activepage > 0 && (
                        <button
                            onClick={props.handleGoBack}
                            type="button"
                            className="back-to color-primary"
                        >
                            <span className="backArrow">
                                <BackArrow />
                            </span>
                            Back
                        </button>
                    )}

                    <div className="bvn-card--input color-black">
                        <label className="card-label color-black">Your BVN</label>
                        <div className="add-amount">
                            <input
                                type="text"
                                className="amount-input normal-input"
                                onChange={handleBvnInputChange}
                                placeholder="01234567890"
                                value={bvnInputs.bvn_no}
                                maxLength="11"
                                onBlur={props.trackedBVNEntered}
                            />
                        </div>

                    </div>

                    <div className="mt-4 sub-info">
                        Your BVN is secure. It does not give us access to your other bank accounts or transactions.
                        We will only have access to your full name, gender, date of birth and phone number.
                    </div>

                    <div className="bvn-card--btn mt-2">
                        <License className="flex-column" bvn_license={true} />
                    </div>

                    <div className="bvn-card--btn flex align-center mt-4 justify-center">

                        <Button
                            text="Next"
                            width='100%'
                            disabled={bvnInputs.bvn_no === "" ||
                                bvnInputs.bvn_no.length !== 11}
                            onButtonClick={executeCaptcha}
                        />
                    </div>

                    <ReCAPTCHA
                        sitekey={RECAPTCHA_SITEKEY}
                        size="invisible"
                        ref={reCaptchaRef}
                    />
                    <p className="bvn-card--remember"><span className="color-secondary text-bold cursor-pointer" onClick={openBVNDialForm}>Click here</span> if you can’t remember your BVN.</p>
                </div>
            </div>
        </FormLayoutWrapper>
    );
}

export default BVNVerification;

const FormLayoutWrapper = styled.div`
width: 717px;
height: auto;
margin:0px auto;
padding-top: 1.875rem;
border-radius: 5px;
border: 1px solid rgba(219, 220, 224, 0.50);
background: var(--White, #FFF);
box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
display: flex;
flex-direction: column;
align-items: center;
position: relative;
font-family: 'Mulish';

.kuda-page--title {
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    font-family: 'Mulish';
    line-height: 34px; 
    margin-top: 34px;
}

.btn--web{
    display: block;
  }


  .bvn-card--wrap{
    width: 417px;
    padding: 1.5rem 1.8rem 2rem 1.8rem; 
}

.bvn-card--input, .bvn-card--btn {
    max-width: 406px;
    margin: auto;
}
.bvn-card--input{

    .card-label{
        font-family: 'Mulish';
    }
        
    .amount-input{
        font-family: 'Mulish';
    }
    .amount-input::placeholder {
        font-family: 'Mulish';
    }
}
.sub-info {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: var(--textColor);
    max-width: 374px;
    margin: auto;
}

.bvn-card--remember{
    color: var(--blackColor);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 406px;
    margin: auto;
    margin: 2rem 0rem;
    text-align: center;
}

@media only screen and (max-width: 991px){
    width: 630px;
}

@media only screen and (max-width: 767px){
    width: 100%;

    .back-to {
        left: 8px;
        top: 15px;
    }
}
   
@media only screen and (max-width: 576px){
    width: 100%;
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
    padding: 0px;

    .btn--web{
        display: none;
    }

    .bvn-card--wrap{
        width: 100%;
        margin-top: 25px;
        margin-bottom: 53px;
        border-radius: 5px;
        border: 1px solid rgba(219, 220, 224, 0.50);
        background: var(--White, #FFF);
        box-shadow: 15px 30px 40px 0px rgba(64, 25, 109, 0.07);
    }

    .back-to {
        left: 0px !important;
        top: 0px !important;
        position: relative;
        margin-bottom: 2rem;
        padding: 0px;
      }
}

@media only screen and (max-width: 425px){
    
    .kuda-page--title{
      font-size: 20px;
      margin-top: 0px;
      font-weight: 900;
      line-height: 25px;
    }
  
    .bvn-card--wrap{
        width: 100%;
    }

      .bvn-card--remember{
        font-size: 12px;
        margin: 1rem 0rem;
      }
}
`
const PageHeading = styled.div`
    max-width: 359px;
    margin: auto;
    margin-bottom: 24px !important;

    > p {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: var(--blackColor);
    }

    @media only screen and(max-width: 425px){

         p {
            max-width: 268px;
            margin: auto;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            color: var(--blackColor);
        }
    }
`;

