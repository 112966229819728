import React from "react";

import styled, { css } from "styled-components";

const Button = ({
    text,
    width,
    onButtonClick,
    disabled,
    isSubmitting,
    type,
    onSubmit,
    background,
    icon,
    extraStyles,
    height,
    borderRadius,
    className
}) => {
    return (
        <ButtonStyled
            width={width}
            onClick={onButtonClick}
            disabled={disabled}
            type={type}
            onSubmit={onSubmit}
            background={background}
            extraStyles={extraStyles}
            height={height}
            borderRadius={borderRadius}
            className={className}
        >
            {isSubmitting ? <span className="form-loading"></span> : text}{" "}
            <div className="button-icon">{icon}</div>
        </ButtonStyled>
    );
};


const ButtonStyled = styled.button`
    width: ${({ width }) => (width ? width : "180px")};
    height: ${({ height }) => (height ? height : "48px")};
    background: ${({ background, disabled }) =>
        background ? background : disabled ? "#9F8CB6" : "#40196D"};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "10px")};
    font-weight: 700;
    font-size: 1rem;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Mulish';
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

    ${({ extraStyles }) =>
        extraStyles &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;

            .button-icon {
                margin-left: 12px;
                margin-top: 3px;
            }
        `}
`;

export default Button;
